import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/Dashboard.vue"),
      },
      // Al
      {
        path: "/al/deposits/history",
        name: "al-deposits-history",
        component: () => import("@/views/al/AlDepositHistory.vue"),
      },
      {
        path: "/al/history/",
        name: "al-history",
        component: () => import("@/views/al/AlHistory.vue"),
      },
      {
        path: "/al/point",
        name: "al-point",
        component: () => import("@/views/al/Point.vue"),
      },
      // Agents
      {
        path: "/agents",
        name: "agents",
        component: () => import("@/views/agents/SubAgents.vue"),
      },
      {
        path: "/agents/al/deposits/history/:subagentid",
        name: "subagents-al-deposits",
        component: () => import("@/views/agents/SubAgentDepositHistory.vue"),
      },
      {
        path: "/agents/al/history/:subagentid",
        name: "agents-al",
        component: () => import("@/views/agents/SubAgentAlHistory.vue"),
      },
      {
        path: "/agents/settlement",
        name: "settlement",
        component: () => import("@/views/agents/Settlement.vue"),
      },
      {
        path: "/agents/login-access",
        name: "login-access",
        component: () => import("@/views/agents/LoginHistory.vue"),
      },
      // Members
      {
        path: "/members",
        name: "members",
        component: () => import("@/views/members/Members.vue"),
      },
      {
        path: "/game/bet-history",
        name: "game-bet-history",
        component: () => import("@/views/members/GameBetHistory.vue"),
      },
      // Live
      {
        path: "/api/document/live/gamelist",
        name: "live-gamelist",
        component: () => import("@/views/document/live/LiveGamelist.vue"),
      },
      {
        path: "/api/document/live/launch",
        name: "live-launch",
        component: () => import("@/views/document/live/LiveLaunch.vue"),
      },
      {
        path: "/api/document/live/balance",
        name: "live-balance",
        component: () => import("@/views/document/live/LiveBalance.vue"),
      },
      {
        path: "/api/document/live/debit",
        name: "live-debit",
        component: () => import("@/views/document/live/LiveDebit.vue"),
      },
      {
        path: "/api/document/live/credit",
        name: "live-credit",
        component: () => import("@/views/document/live/LiveCredit.vue"),
      },
      {
        path: "/api/document/live/rollback",
        name: "live-rollback",
        component: () => import("@/views/document/live/LiveRollback.vue"),
      },
      // Settings
      {
        path: "/settings/bet-chips",
        name: "bet-chips",
        component: () => import("@/views/settings/GameChipSetting.vue"),
      },
      {
        path: "/settings/bet-limit",
        name: "bet-limit",
        component: () => import("@/views/settings/BaccaratBetLimit.vue"),
      },
      {
        path: "/settings/others",
        name: "other-setting",
        component: () => import("@/views/settings/OtherSetting.vue"),
      },
      // Api Logs
      {
        path: "/api/logs/:type",
        name: "api-logs",
        component: () => import("@/views/api/ApiLogs.vue"),
      },
      {
        path: "/games/result",
        name: "games-result",
        component: () => import("@/views/results/GameResult.vue"),
      },
    ],
  },
  // Result
  {
    path: "/results/:betId",
    name: "defensil-result",
    component: () => import("@/views/results/BetResult.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "/code/:agent",
        name: "login-via-code",
        component: () => import("@/views/auth/LoginViaCode.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
