const messages = {
  Login: {
    Title: "DEFENSIL 로그인",
    Username: "아이디",
    Password: "비밀번호",
    Login: "로그인",
    Failed: "아이디 혹은 암호가 맞지 않습니다.",
  },
  Languages: {
    en: "영어",
    ko: "한국어",
  },
  UserMenu: {
    Language: "언어",
    Settings: "설정",
    Logout: "로그아웃",
  },
  Menu: {
    Dashboard: "대시보드",
    AgentManage: "에이전트 관리",
    AgentList: "에이전트 목록",
    SettlementManage: "정산 관리",
    LoginHistoryIpManage: "접속 로그 & IP관리",
    AlManage: "알 관리",
    AlAddHistory: "알 지급/차감",
    AlHistory: "알 내역",
    MemberManage: "회원 관리",
    MemberList: "회원 목록",
    MemberBetHistory: "베팅내역",
    ApiManual: "API 문서",
    ApiLogs: "API 로그",
    Success: "정상 로그",
    Error: "오류 로그",
    GameManage: "게임 관리",
    GameChipSetting: "게임 칩 설정",
    BetLimitSetting: "베팅한도 설정",
    OtherSetting: "기타 설정",
    ResultManage: "결과값 확인",
    Point: "포인트",
  },
  DateRange: {
    Start: "부터",
    End: "까지",
    Today: "오늘",
    Yesterday: "어제",
    ThisWeek: "이번 주",
    LastWeek: "지난 주",
    ThisMonth: "이번 달",
    LastMonth: "지난 달",
  },
  Agents: {
    Name: "에이전트",
    Code: "코드",
    Password: "비밀번호",
    IpAddress: "아이피",
    Domain: "사이트",
    AlWallet: "카지노 알",
    AlSlot: "슬롯 알",
    SearchName: "에이전트 조회",
    SearchCode: "코드 조회",
    NewSubAgent: "하부 에이전트 추가",
    SubAgent: "에이전트 이름",
    SiteAddress: "사이트 주소",
    SeamlessURLBalance: "실시간 카지노 Balance 주소",
    SeamlessURLDebit: "실시간 카지노 Debit 주소",
    SeamlessURLCredit: "실시간 카지노 Credit 주소",
    SeamlessURLRollback: "실시간 카지노 Rollback 주소",
    NewSubAgentFailed: "하부 에이전트 추가 실패",
    NewSubAgentSuccess: "하부 에이전트가 추가되었습니다",
    Action: "액션",
    Level: "레벨",
    State: "상태",
    Count: "에이전트 수",
    Betamount: "베팅금액",
    Winamount: "적중금액",
    Profit: "수익",
    Rolling: "총베팅금액",
    RollingProfit: "수수료",
    RollPercent: "롤링 요율",
    Normal: "정상",
    Stopped: "중지",
    Suspended: "탈퇴",
    BetAmount: "총베팅",
    HitAmount: "적중액",
    RollingFee: "롤링피",
    FinalProfit: "최종수익",
    RollingRate: "롤링 요율 (%)",
    UpperAgent: "상위 에이전트",
    Edit: "수정",
    EditSuccess: "수정 완료되었습니다.",
    EditFailed: "수정 실패되었습니다.",
    EditAgentFailed: "에이전트 수정 실패",
    EditAgentSuccess: "에이전트 수정 완료되었습니다.",
  },
  LoginHistory: {
    IP: "아이피",
    Date: "로그인일자",
    Block: "아이피 차단",
    Unblock: "아이피 차단해제",
    Info: "정부",
    Title: "접속 로그",
    BlockMessage: "이 IP를 차단하시겠습니까?",
    Confirm: "확인",
    Cancel: "취소",
    BlockSuccess: "IP가 차단되었습니다.",
    BlockFailed: "IP 차단 실패되었습니다. 다시 시도해주세요.",
    BlockList: "아이피 차단 목록",
    UnblockMessage: "이 IP를 차단 해제하시겠습니까?",
    UnblockSuccess: "IP가 차단 해제되었습니다.",
    UnblockFailed: "IP 차단 해제 실패되었습니다. 다시 시도해주세요.",
  },
  Deposit: {
    Agent: "에이전트",
    Type: "타입",
    Amount: "지급 금액",
    State: "상태",
    Date: "처리 날짜",
    TotalDeposit: "총 알 지급금액",
    POINT: "포인트 전환",
    ADD: "알 추가",
    DEDUCT: "알 차감",
    AddAmount: "알 충전 금액",
    DeductAmount: "알 차감 금액",
    AddAlSuccess: "알 추가 완료되었습니다.",
    DeductAlSuccess: "알 차감 완료되었습니다.",
    SUB_ADD: "하부 알 추가",
    SUB_DEDUCT: "하부 알 차감",
  },
  DashboardSummary: {
    Title: "사용 요약",
    CurrentAl: "현재 알금액",
    Members: "회원",
    MonthCasinoLoseAmount: "카지노 총 비적중금액 (당월)",
    MonthCasinoWinAmount: "카지노 총 적중금액  (당월)",
    MonthCasinoAlUsage: "카지노 알 사용 (당월)",
    SubAgentCurrentAl: "하부 현재 알금액",
    TotalDeposit: "총 알 추가 금액",
  },
  AlHistory: {
    TotalUsage: "총 알 사용",
    Deposit: "입금금액",
    Withdrawal: "출금금액",
    Before: "기존 보유",
    Usage: "사용량",
    After: "현재 보유",
    Settlement: "정산",
    Agent: "에이전트",
    Memo: "메모",
    MemoEdit: "메모 수정",
    MemoEditSuccess: "메모 수정되었습니다.",
  },
  Game: {
    // live
    AG: "아시안게이밍",
    88: "88",
    CGY: "카가얀",
    CMB: "Maxi",
    DG: "드림게이밍",
    EVO: "에볼루션",
    OGP: "오리엔탈 플러스",
    AlMonthlyUsage: "당월 게임 알 사용",
    Game: "게임",
    GameCode: "게임 코드",
    ProviderCode: "벤더 코드",
    AlUsageAmount: "알 사용 금액",
    SubGamelist: "게임 리스트",
  },
  GameRoom: {
    Roomno: "방번호",
    Roomorder: "차수",
    Category: "범주",
    State: "상태",
    Signal: "신호",
    Open: "열기",
    Close: "닫기",
    BetOpen: "베팅 시작중",
    BetClosed: "베팅 종료중",
    BetResult: "베팅 결과중",
    NoData: "신호 없음",
    Add: "게임 방 추가",
    GameType: "게임 타입",
    SpeedBaccarat: "스피드 바카라",
    Baccarat: "바카라",
    DragonTiger: "드라곤 타이거",
    AddGame: "게임 추가",
    GameCode: "게임 코드",
    GameName: "게임 이름",
    SQLModel: "SQL 모델",
    AddGameSuccess: "새 게임 추가되었습니다.",
    VideoSource: "영상 주소",
    UpdateVideo: "영상 변경",
    ViewVideo: "영상 보기",
    UpdateVideoSuccess: "영상 변경되었습니다.",
    Result: "결과값",
    Shoe: "슈값",
    Card: "카드값",
  },
  OtherSetting: {
    AllowCrossBet: "양방 베팅",
    Allowed: "허용",
    NotAllowed: "미허용",
  },
  BetSummary: {
    Title: "베팅분석",
    BetAmount: "베팅금액",
    WinAmount: "적중금액",
    Profit: "수익금액",
    MemberCount: "회원수",
  },
  Members: {
    ID: "아이디",
    Nickname: "닉네임",
    Wallet: "보유금액",
    RegDate: "가입 날짜",
    SearchID: "아이디 조회",
  },
  MoneyTransfer: {
    Before: "이동 전",
    Amount: "이동 금액",
    After: "이동 후",
    ProcessDate: "처리 날짜",
    All: "전체",
    DEPOSIT: "게임 충전",
    WITHDRAW: "게임 환전",
    Search: "조회",
  },
  BetHistory: {
    Room: "방",
    Round: "차수",
    Date: "베팅 날짜",
    Pending: "미처리",
    Completed: "처리 완료",
    Cancelled: "환급",
    Metadata: "상세",
    Winamount: "변동금액",
    Betposition: "베팅대상",
  },
  BetChip: {
    Chip: "칩",
    Amount: "칩금액",
    Update: "베팅칩을 변경되었습니다.",
  },
  Betposition: {
    player: "플레이어",
    banker: "뱅커",
    player_pair: "플레이어 페어",
    banker_pair: "뱅커 페어",
    tie: "타이",
  },
  BetLimit: {
    Position: "베팅",
    Player: "플레이어",
    Banker: "뱅커",
    Tie: "타이",
    Pair: "페어",
    Minimum: "베팅 최소한도",
    Maximum: "베팅 제한한도",
    Update: "베팅제한 변경되었습니다.",
  },
  ApiLogs: {
    Success: "정상",
    Error: "오류",
    Command: "거래",
    ResponseCode: "응답 코드",
    Request: "REQUEST",
    Response: "RESPONSE",
    BetID: "TXN 아이디",
    Balance: "BALANCE",
    Debit: "DEBIT",
    Credit: "CREDIT",
    Rollback: "ROLLBACK",
  },
  ApiManual: {
    API: "카지노",
    SLOT: "슬롯",
    RealtimeCasino: "실시간 카지노",
    Gamelist: "게임리스트",
    Auth: "회원 가입 / 로그인",
    Balance: "Balance 콜백",
    Debit: "Debit 콜백",
    Credit: "Credit 콜백",
    Rollback: "Rollback 콜백",
    DetailInfo: "상세 내역",
    MoneyTransfer: "머니 이동",
    Deposit: "충전",
    BalanceAmt: "게임 보유",
    Withdrawal: "환전",
    MoneyTransferSlot: "머니 이동 슬롯",
    MoneyTransferSnow2: "머니 이동 SNOW2",
    ApiAccount: "API 계정",
    ApiURL: "API 주소",
    SampleCode: "샘플 코드",
    PostAddress: "주소",
    Header: "Request Header",
    RequestBody: "Request Body",
    UsernameNotice: "UNIQUE INDEX로서 숫자값(8글자이내)을 넣어주세요.",
    NameNotice: "파트너 회원 이름",
    IDNotice: "UNIQUE INDEX로서 파트너 AUTOINCREMENT, UUID를 넣어주세요.",
    GameNotice: "Defensil 게임 코드 (게임리스트에서 확인)",
    GameIDNotice: "Defensil 슬롯 게임 코드 (게임리스트에서 확인)",
    SnowMemberIDNotice:
      "Defensil 멤버 아이디 (가입 / 로그인 호출시에 member_id)",
    AmountNotice: "입금 금액",
    Submit: "실행",
    WithdrawalNotice: "환전 공지",
    WithdrawalNoticeMessage: "모든 회원 잔액이 인출됩니다.",
    DateTime: "날시 (YYYY-MM-DD HH:mm:ss)",
    BetHistoryCron: "베팅내역 CRON 배치",
    BetHistoryCronMessage:
      "최신 베팅 기록을 얻기 위해 15분마다 API를 호출하는 크론 작업을 생성해 주십시오.",
    Exist: "있음",
    SuccessResponse: "정상 응답",
    ErrorResponse: "에러 응딥",
    DebitTxnID: "데빗 트랜색션 아이디 (UNIQUE INDEX로서 넣어주세요.)",
    GameCode: "게임 코드 / 이름",
    GameType: "라이브 (LIVE), 슬롯 (SLOT), 스포츠 (SPORT)",
    Roomno: "게임 / 테이블 방",
    Roomorder: "차수",
    HistoryId: "상세 내역 아이디",
    RollbackAmount: "환급 금액",
    RollbackReason: "환급 이유",
  },
  BettingLimit: {
    Casino: "카지노 베팅한도",
    Amount: "베팅한도 금액",
    GameLimit: "게임 내 한도",
    CustomLimit: "개별 한도 적용",
    Update: "변경",
    Success: "카지노 베팅한도가 적용되었습니다.",
  },
  Point: {
    Savings: "적립",
    SubSavings: "하부 적립",
    Transfer: "전환",
    SubTransfer: "하부 전환",
    Current: "현재 포인트",
    PointTransfer: "포인트 전환",
    PointTransferAmount: "포인트 전환 금액",
    TransferSuccess: "포인트 전환 완료되었습니다.",
    Amount: "포인트금액",
  },
  Cancel: "취소",
  Loading: "로딩...",
  Copy: "복사",
  TryAgain: "다시 시도해주세요",
  Level: "레벨",
  Edit: "수정",
  Save: "업데이트",
  gameData: {
    1: "바카라",
    Baccarat: "바카라",
    baccarat: "바카라",
    BaccaratClassic: "바카라",
    BAC: "바카라",
    header: "거래 상세 내약",
    playerName: "유저아이디",
    on: "@",
    game: "게임",
    tableId: "테이블 아이디",
    settledAt: "종료 시간",
    bankerHand: "뱅커 핸드",
    playerHand: "플레이어 핸드",
    score: "Score",
    status: {
      label: "상태",
      Resolved: "처리됨",
      1: "처리됨",
    },
    bets: "베팅",
    amount: "금액",
    netCash: "회원 승패",
    transactionId: "거래 아이디",
    placeTime: "베팅 시간",
    gameSummary: "게임 요약",
    results: "결과",
    totalBet: "총 베팅금액",
    totalPayout: "총 지불금액",
    totalNet: "총 회원승패",
    result: {
      a: "뱅커",
      b: "뱅커 플레이어 페어",
      c: "벵커 뱅커 페어",
      d: "뱅커 플레이어-뱅커 페어",
      e: "플레이어",
      f: "플레이어 플러이어 페어",
      g: "플레이어 뱅커 페어",
      h: "플레이어 플레이어-뱅커 페어",
      i: "타이",
      j: "타이 플러이어 페어",
      k: "타이 뱅커 페어",
      l: "타이 플레이어-뱅커 페어",
    },
  },
  API: "API",
};

export default messages;
