const messages = {
  dashboard: "Dashboard",
  layoutBuilder: "Layout builder",
  craft: "Crafted",
  pages: "Pages",
  profile: "Profile",
  profileOverview: "Overview",
  projects: "Projects",
  campaigns: "Campaigns",
  documents: "Documents",
  connections: "Connections",
  wizards: "Wizards",
  horizontal: "Horizontal",
  vertical: "Vertical",
  account: "Account",
  accountOverview: "Overview",
  settings: "Settings",
  authentication: "Authentication",
  basicFlow: "Basic Flow",
  signIn: "Sign-in",
  signUp: "Sign-up",
  passwordReset: "Password Reset",
  error404: "Error 404",
  error500: "Error 500",
  apps: "Apps",
  chat: "Chat",
  privateChat: "Private Chat",
  groupChat: "Group Chat",
  drawerChat: "Drawer Chat",
  widgets: "Widgets",
  widgetsLists: "Lists",
  widgetsStatistics: "Statistics",
  widgetsCharts: "Charts",
  widgetsMixed: "Mixed",
  widgetsTables: "Tables",
  widgetsFeeds: "Feeds",
  changelog: "Changelog",
  docsAndComponents: "Docs & Components",
  megaMenu: "Mega Menu",
  exampleLink: "Example link",
  modals: "Modals",
  general: "General",
  inviteFriends: "Invite Friends",
  viewUsers: "View Users",
  upgradePlan: "Upgrade Plan",
  shareAndEarn: "Share & Earn",
  forms: "Forms",
  newTarget: "New Target",
  newCard: "New Card",
  newAddress: "New Address",
  createAPIKey: "Create API Key",
  twoFactorAuth: "Two Factor Auth",
  createApp: "Create App",
  createAccount: "Create Account",
  documentation: "Documentation",
  components: "Components",
  resources: "Resources",
  activity: "Activity",
  customers: "Customers",
  gettingStarted: "Getting Started",
  customersListing: "Customers Listing",
  customerDetails: "Customers Details",
  calendarApp: "Calendar",
};

export default messages;
